
import Vue, { PropType } from 'vue';
import { Category } from '@/interfaces/category.interface';

export default Vue.extend({
  name: 'GridCategoryItem',

  props: {
    item: Object as PropType<Category>,
  },

  methods: {
    async openSubcategory(id: string) {
      this.$router.push(`/categories/${id}`);
    },
  },
});
